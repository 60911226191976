import { PageContainer } from '@ant-design/pro-layout';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import introductionImg from 'assets/images/self-introduction-icon.png';
import IntroductionForm from 'components/Self_Introduction/IntroductionForm';
import FutureGoalForm from 'components/Self_Introduction/FutureGoalForm';
import ImportantThingInWorkForm from 'components/Self_Introduction/ImportantThingWorkForm';
import IntroductionList from 'components/Self_Introduction/IntroductionList';
import useFetchList from 'hooks/useList';
import { IListIntroduction, IUpdateIntroduction } from 'models/introductions/interface';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import introductionsQuery from 'models/introductions/query';
import { useAppDispatch } from 'store';
import './styles.less';
import Helper from 'utils/helpers';
import { useFetchDetail, useMutate } from 'hooks';

const { Title } = Typography;

const IntroductionPage = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const header = {
    title: t('menuBar:introduction'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:introduction')} `,
        },
      ],
    },
  };
  const [introductions, setIntroductions] = useState<any>({});
  const {
    list: introductionList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<IListIntroduction>(introductionsQuery.introductionItemList);

  // init value for section future goal and important thing in work
  const { data } = useFetchDetail<IUpdateIntroduction>(introductionsQuery.getSelfIntroduction);

  const resetInitialValue = () => {
    setIntroductions({
      memo: '',
      selfIntroductionDetail: '',
      linkToAP: false,
    });
    setIntroductions({});
  };
  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutate<{
    id: string;
  }>(introductionsQuery.deleteIntroduction);

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteItem(
              { id: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  resetInitialValue();
                  refetchList();
                },
              },
            );
          },
        },
      }),
    );
  };
  // scroll to top form section when edit
  const handleScrollSelfIntroductionForm = () => {
    const element = document.querySelector('.self-introduction-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEditItem = (item) => {
    setIntroductions(item);
    handleScrollSelfIntroductionForm();
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper introduction-page-wrapper">
        <div className="self-introduction-scroll"></div>
        <Typography style={{ padding: '0 24px', fontSize: 14, fontWeight: 500, lineHeight: 1.5 }}>
          自己紹介文を登録しましょう。
          <br />
          利用するシーンや、 紹介をする相手に応じて
          <br />
          メッセージを変えてみましょう。
        </Typography>
        <div className="green-opacity-bg inner-content block-01 introduction-form-wrapper">
          <img src={introductionImg} className="introduction-img" alt="icon" />
          <Title level={4}>{t('menuBar:introduction')}</Title>
          <IntroductionForm initialValue={introductions} resetInitialValue={resetInitialValue} />
          {introductionList?.length > 0 && (
            <IntroductionList
              data={introductionList}
              initLoading={fetchData || isLoadingDelete}
              handleEdit={handleEditItem}
              handleDelete={handLeDeleteItem}
            />
          )}
        </div>

        <div className="mt-40 green-opacity-bg inner-content block-01">
          <FutureGoalForm initialValue={data} />
        </div>
        <div className="mt-40 green-opacity-bg inner-content block-01">
          <ImportantThingInWorkForm initialValue={data} />
        </div>
      </Space>
    </PageContainer>
  );
};

export default IntroductionPage;
