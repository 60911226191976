import { PageContainer } from '@ant-design/pro-layout';
import { Typography, Space, Button } from 'antd';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ChangeEmailForm from 'components/ChangeEmailForm';
import ChangePasswordsForm from 'components/ChangePasswordsForm';
import FormModal from 'components/FormModal';
import { setFormModal, setConfirmModal } from 'providers/GeneralProvider/slice';
import UpdateProfileForm from 'components/auth/UpdateProfile';
import { useAppDispatch } from 'store';
import { useFetchUser, useMutate } from 'hooks';
import Helper from 'utils/helpers';
import authQuery from 'models/auth/query';
import SalesEngineerLinkList from 'components/SalesEngineerLinkList';
import saleCompanyQuery from 'models/sale-company/query';
import useFetchList from 'hooks/useList';
import { ISaleCompany } from 'models/sale-company/interface';
import ChangeAvatarPage from './ChangeAvatarPage';
import './styles.less';
import HelperText from '../../components/Form/HelperTextTooltip';

const { Title, Text } = Typography;

const SettingPage = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [perPage, setPerPage] = useState<number>(5);
  const { data, refetch } = useFetchUser({ enabled: true });
  const { mutateAsync: updateLink } = useMutate<unknown>(authQuery.updateLinkCompany);
  const { mutateAsync: unlinkSaleCompany } = useMutate<unknown>(saleCompanyQuery.unlinkSaleCompany);
  const {
    list: listDataSaleCompany,
    refetch: refetchListSaleCompany,
    total,
  } = useFetchList<ISaleCompany>({
    ...saleCompanyQuery.list,
    queryKey: ['sale-companies-list'],
    customParams: {
      perPage: perPage,
      sort: 'createdAt@desc',
    },
  });

  const showMoreSaleCompany = () => {
    setPerPage(999);
    refetchListSaleCompany();
  };

  const showChangeEmailModal = () => {
    dispatch(
      setFormModal({
        key: 'requestChangeEmail',
        isOpen: true,
        data: {},
      }),
    );
  };

  const showChangePasswordsModal = () => {
    dispatch(
      setFormModal({
        key: 'changePasswords',
        isOpen: true,
        data: {},
      }),
    );
  };

  const handleLinkCompanyPopup = () => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'request-change-link-company',
          content: `株式会社${_get(
            data,
            'linkedCompany.name',
            'Company name',
          )}との連携を解除してよろしいでしょうか?`,
          centered: true,
          zIndex: 9999,
          onOk: () => {
            updateLink(
              {},
              {
                onSuccess: () => {
                  Helper.toasts('', t('connectionAP:unlinkSuccess'), 'success');
                  refetch();
                },
              },
            );
          },
        },
      }),
    );
  };
  const handleUnLinkSaleCompanyPopup = (data: { companies: string[]; companyName?: string }) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'request-change-link-company',
          content: `${data?.companyName}との連携を解除します。よろしいでしょうか。`,
          centered: true,
          zIndex: 9999,
          onOk: () => {
            unlinkSaleCompany(
              { companies: data.companies },
              {
                onSuccess: () => {
                  Helper.toasts('', t('connectionAP:unlinkSuccess'), 'success');
                  refetchListSaleCompany();
                },
              },
            );
          },
        },
      }),
    );
  };

  return (
    <PageContainer>
      <Space direction="vertical" className="setting-page-wrapper">
        <Title level={4} style={{ marginBottom: 14 }}>
          アカウント情報
        </Title>
        <div className="green-opacity-bg inner-content block-01">
          <Space align="center" style={{ marginBottom: 19 }}>
            <Text className="title">
              メールアドレス
              <HelperText text={t('formHelpTextProfilePage:emailLabel')} />
            </Text>
            <Text className="content">{_get(data, 'email', '')}</Text>
            <Button size="small" type="primary" onClick={showChangeEmailModal}>
              変更
            </Button>
          </Space>
          <Space align="center">
            <Text className="title">パスワード</Text>
            <Text className="content">・・・・・・・・・・・・</Text>
            <Button size="small" type="primary" onClick={showChangePasswordsModal}>
              変更
            </Button>
          </Space>
        </div>
        <Space direction="vertical">
          <Title level={4}>
            アイコンカラー
            <HelperText text={t('formHelpTextProfilePage:avatarColor')} />
          </Title>
          <ChangeAvatarPage />
        </Space>
        <Space direction="vertical" className="change-link-block">
          <Title level={4}>
            Aperportと連携中の会社
            <HelperText text={t('formHelpTextProfilePage:companyLinked')} />
          </Title>
          <Title level={5}>エンジニア担当としての連携</Title>
          <div className="green-opacity-bg inner-content block-01 change-link-wrapper">
            <Space align="center" style={{ marginBottom: '0px' }}>
              {_isEmpty(_get(data, 'linkedCompany._id')) && (
                <Text className="title not">未連携</Text>
              )}
              {!_isEmpty(_get(data, 'linkedCompany._id')) && (
                <Text className="title">{_get(data, 'linkedCompany.name', 'Company name')}</Text>
              )}
              <Button
                type="primary"
                className="second-color btn-change-link-company"
                disabled={_isEmpty(_get(data, 'linkedCompany._id'))}
                onClick={handleLinkCompanyPopup}
              >
                解除する
              </Button>
            </Space>
          </div>

          <Title level={5}>営業担当としての連携</Title>
          <SalesEngineerLinkList
            data={listDataSaleCompany}
            totalData={total}
            handleUnLinkSaleCompanyPopup={handleUnLinkSaleCompanyPopup}
            onLoadMore={showMoreSaleCompany}
          />
        </Space>
        <Space direction="vertical">
          <UpdateProfileForm />
        </Space>
      </Space>

      <FormModal keyModal="requestChangeEmail" title="メールアドレスの変更">
        <ChangeEmailForm />
      </FormModal>

      <FormModal keyModal="changePasswords" title="パスワードの変更">
        <ChangePasswordsForm />
      </FormModal>
    </PageContainer>
  );
};

export default SettingPage;
