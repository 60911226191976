/* eslint-disable max-len */
import { Button, Row, Form, Input, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import _get from 'lodash/get';
import { InfoCircleOutlined } from '@ant-design/icons';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import { useMutate } from 'hooks';
import './styles.less';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';
import { ILoginPayload } from 'models/auth/interface';
import { Regex } from 'utils/constants';

const renderError = (message: string) => (
  <div className="helper-wrapper">
    <div className="error-text">
      <InfoCircleOutlined className="info-icon" type="info-circle" />
      {message}
    </div>
  </div>
);

const LoginPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const hasInvitedWithAP = _get(location, 'state.params.inviterId');
  const tokenInviteAP = _get(location, 'state.params.token');
  const typeUserInviteAP = _get(location, 'state.params.type');

  const [form] = Form.useForm();
  const { mutateAsync: login, isLoading } = useMutate<ILoginPayload, { accessToken: string }>(
    authQuery.login,
  );

  if (Helper.getAuthToken()) {
    history.push('/dashboard');
  }

  const handleLogin = (values: ILoginPayload) => {
    login(
      { email: values.email.toLocaleLowerCase(), password: values.password },
      {
        onSuccess: (data) => {
          if (data.accessToken) {
            Helper.storeAuthToken(data.accessToken);
          }
          if (!hasInvitedWithAP || values?.email !== _get(location, 'state.params.email')) {
            history.push('/dashboard');
          } else {
            history.push('engineer-linking-confirmation', {
              tokenInviteAP,
              typeUserInviteAP,
            });
          }
        },
      },
    );
  };

  return (
    <Spin spinning={isLoading}>
      <HeaderComponent>
        <Link to={'/pre-check-email-sign-up'}>
          <Button type="primary" size="small">
            会員登録
          </Button>
        </Link>
      </HeaderComponent>
      <div className="login-page-wrapper">
        <GreenBox title="ログイン">
          <div className="login-form">
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                email: '',
                password: '',
              }}
              name="login"
              validateTrigger={['onBlur']}
              onFinish={(values) => handleLogin(values)}
            >
              <Row justify="center">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: renderError('メールアドレスを入力してください。'),
                    },
                    {
                      pattern: Regex.EMAIL,
                      message: renderError('不正なメールアドレスの形式です。'),
                    },
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input
                    className="size-s"
                    id="login-input"
                    placeholder="メールアドレス"
                    autoFocus
                  />
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: renderError('パスワードを入力してください。'),
                    },
                  ]}
                >
                  <Input.Password
                    id="password-input"
                    placeholder="パスワード"
                    autoComplete="nope"
                    className="size-s"
                  />
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item shouldUpdate className="submit">
                  {() => (
                    <Button
                      className="login-btn second-color"
                      htmlType="submit"
                      type="primary"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                      }
                    >
                      ログイン
                    </Button>
                  )}
                </Form.Item>
              </Row>
              <Row justify="center">
                <a className="reset-password" href="/reset-password">
                  パスワードをお忘れの方はこちら
                </a>
              </Row>
              <Row justify="center">
                <a className="register-link" href="/pre-check-email-sign-up">
                  会員登録はこちら
                </a>
              </Row>
            </Form>
          </div>
        </GreenBox>
      </div>
    </Spin>
  );
};

export default LoginPage;
