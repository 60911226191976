import { useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import WorkExperienceForm from 'components/skills/WorkExperienceForm';
import WorkExperienceList from 'components/skills/WorkExperienceList';
import { useAppDispatch } from 'store';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import useFetchList from 'hooks/useList';
import useMutate from 'hooks/useMutate';
import skillsQuery from 'models/skills/query';
import Helper from 'utils/helpers';

import { IListWorkExperience } from 'models/skills/interface';

const { Title } = Typography;

const ExperiencePage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:experience'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:skills')} / ${t('menuBar:experience')}`,
        },
      ],
    },
  };
  const dispatch = useAppDispatch();
  // state
  const [workExperience, setWorkExperience] = useState({});
  const {
    list: dataList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<IListWorkExperience>({ ...skillsQuery.workExperienceList });

  const { mutateAsync: deleteFunc, isLoading: isLoadingDelete } = useMutate<{
    workExperience: string;
  }>(skillsQuery.deleteWorkExperience);

  const resetInitialValue = () => {
    setWorkExperience({
      projectName: '',
      endUser: '',
      responsibilityCompany: '',
      periodStart: '',
      periodEnd: '',
      roles: [],
      inChargeProcesses: [],
      technologies: [],
      projectOverview: '',
    });
  };

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteFunc(
              { workExperience: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  refetchList();
                  resetInitialValue();
                },
              },
            );
          },
        },
      }),
    );
  };
  // scroll to top form section when edit
  const handleScrollWorkExForm = () => {
    const element = document.querySelector('.work-ex-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEditItem = (item) => {
    setWorkExperience(item);
    handleScrollWorkExForm();
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper work-experience-wrapper">
        <div className="work-ex-scroll"></div>
        <Typography style={{ padding: '0 15px 0 28px', fontWeight: 500 }}>
          ご自身の経験・スキルを説明するのにふさわしい代表的なプロジェクトを登録しましょう。
        </Typography>
        <div className="green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:workExperienceTitle')}</Title>
          <WorkExperienceForm
            refreshDataList={refetchList}
            initialValue={workExperience}
            resetInitialValue={resetInitialValue}
          />
          {dataList?.length > 0 && (
            <WorkExperienceList
              className="work-experience-list"
              data={dataList}
              initLoading={fetchData || isLoadingDelete}
              handleEdit={handleEditItem}
              handleDelete={handLeDeleteItem}
            />
          )}
        </div>
      </Space>
    </PageContainer>
  );
};

export default ExperiencePage;
