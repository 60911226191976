import { Button, Space, Typography } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import dropdownIcon from 'assets/images/icon_dropdown.svg';
import { ISaleCompany } from 'models/sale-company/interface';

interface SalesEngineerLinkListProps {
  data: ISaleCompany[];
  totalData: number;
  onLoadMore?: () => void;
  handleUnLinkSaleCompanyPopup: (data: { companies: string[]; companyName?: string }) => void;
}

const SalesEngineerLinkList: React.FC<SalesEngineerLinkListProps> = ({
  data,
  totalData = 0,
  onLoadMore,
  handleUnLinkSaleCompanyPopup,
}) => {
  const hasData = data.length > 0;
  return (
    <div className="green-opacity-bg block-01 inner-content change-link-wrapper sale-link-wrapper">
      {!hasData && totalData === 0 && (
        <Space align="center" className="sale-record">
          <Typography.Text className="title not">未連携</Typography.Text>
          <Button type="primary" className="second-color btn-change-link-company" disabled={true}>
            解除する
          </Button>
        </Space>
      )}
      {hasData &&
        data.map((record, index) => (
          <Space key={index} align="center" className="sale-record">
            {!_isEmpty(record._id) && (
              <Typography.Text className="title">{record.company.name}</Typography.Text>
            )}
            <Button
              type="primary"
              className="second-color btn-change-link-company"
              disabled={_isEmpty(record._id)}
              onClick={() =>
                handleUnLinkSaleCompanyPopup({
                  companies: [record.companyId],
                  companyName: record.company.name,
                })
              }
            >
              解除する
            </Button>
          </Space>
        ))}
      {hasData && data.length < totalData && (
        <Space align="center" className="sale-record last">
          <Button onClick={onLoadMore} type="default">
            もっと見る <img src={dropdownIcon} alt="" />
          </Button>
        </Space>
      )}
    </div>
  );
};

export default SalesEngineerLinkList;
