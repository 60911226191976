import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import TextInput from 'components/Form/TextInputNew';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate, useFetchDetail } from 'hooks';
import Helper from 'utils/helpers';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import './style.less';
import '../../skills/skills-style.less';
import TextArea from 'components/Form/TextArea';
import CheckBox from 'components/Form/CheckBox';
import HelperText from 'components/Form/HelperTextTooltip';
import {
  ICreateIntroduction,
  IntroductionInitValue,
  IUpdateIntroductionItem,
} from 'models/introductions/interface';
import introductionsQuery from 'models/introductions/query';
import IntroductionSchema from './validate';

type Introductions = InferType<typeof IntroductionSchema>;

const IntroductionForm: React.FC<{
  initialValue?: IntroductionInitValue;
  resetInitialValue: () => void;
}> = ({ initialValue, resetInitialValue }) => {
  const [initialForm, setInitialForm] = useState({});

  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<Introductions>({
    resolver: yupResolver(IntroductionSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();

  const clearFormValues = () => {
    reset({});
    resetInitialValue();
    setInitialForm({});
  };
  // get introductions list
  const { refetch: refetchList } = useFetchList<Introductions>(
    introductionsQuery.introductionItemList,
  );
  const { mutateAsync: createIntroduction, isLoading: loadingCreate } =
    useMutate<ICreateIntroduction>(introductionsQuery.createIntroduction);
  const { mutateAsync: updateIntroductionItem, isLoading: loadingUpdate } =
    useMutate<IUpdateIntroductionItem>(introductionsQuery.updateIntroductionItem);

  useEffect(() => {
    setInitialForm({
      ...initialValue,
    });
  }, [initialValue]);
  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  const handleSubmitIntroduction = (values) => {
    if (_isEmpty(initialValue)) {
      createIntroduction(
        {
          ...values,
          linkToAP: !!values?.linkToAP,
        },
        {
          onSuccess: () => {
            Helper.toasts('', 'ユーザー情報の更新成功', 'success');
            reset();
            refetchList();
          },
        },
      );
    } else {
      updateIntroductionItem(
        {
          linkToAP: !!values?.linkToAP,
          memo: values?.memo,
          selfIntroductionDetail: values?.selfIntroductionDetail,
          selfIntroductionItem: _get(initialForm, '_id', ''),
        },
        {
          onSuccess: () => {
            Helper.toasts('', 'ユーザー情報の更新成功', 'success');
            clearFormValues();
            refetchList();
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container introduction-form-container"
      onSubmit={handleSubmit(handleSubmitIntroduction)}
    >
      <Spin spinning={loadingCreate || loadingUpdate}>
        <div className="introduction-form-block">
          <div className="row-block">
            <TextInput
              name="memo"
              label={t('introductions:memo')}
              control={control}
              required
              placeholder="入力してください"
              formLayout="vertical"
              colLabel={24}
              maxLength={100}
              colWrapper={24}
              helperText={_get(dataHelpText, 'value.formHelpTextIntroductionPage.memo')}
            />
          </div>
          <div className="row-block full-row">
            <TextArea
              name="selfIntroductionDetail"
              control={control}
              required
              label={t('introductions:selfIntroductionDetail')}
              placeholder="入力してください"
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(
                dataHelpText,
                'value.formHelpTextIntroductionPage.selfIntroductionDetail',
              )}
            />
          </div>
          <div className="link-to-aperport">
            <CheckBox name="linkToAP" control={control} label={t('introductions:linkToAperport')} />
            <HelperText text={t('formHelpTextIntroductionPage:linkToAperport')} />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(initialForm) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => {
                  clearFormValues();
                }}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default IntroductionForm;
