/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Row, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import type { InferType } from 'yup';
import { TextInput } from 'components/Form';
import GreenBox from 'components/GreenBox';
import HeaderComponent from 'components/Header';
import { useMutate } from 'hooks';
import { IPreCheckEmailPayload } from 'models/auth/interface';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';

import useFetchList from 'hooks/useList';
import { ISettingListData } from 'models/setting/interface';
import settingQuery from 'models/setting/query';
import './styles.less';
import PreCheckEmailSchema from './validate';

type PreCheckEmailFormValues = InferType<typeof PreCheckEmailSchema>;

const PreCheckEmailSignUpPage = (): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PreCheckEmailFormValues>({
    resolver: yupResolver(PreCheckEmailSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { push } = useHistory();
  const { mutateAsync, isLoading } = useMutate<IPreCheckEmailPayload>(authQuery.preCheckEmail);
  const { list: settingList } = useFetchList<ISettingListData>(settingQuery.settingList);

  const termsOfUseLink =
    settingList.find((setting) => setting.name === 'termsOfUseLink')?.value || '';

  const privacyPolicyLink =
    settingList.find((setting) => setting.name === 'privacyPolicyLink')?.value || '';

  const dataPolicyLink =
    settingList.find((setting) => setting.name === 'dataPolicyLink')?.value || '';

  if (Helper.getAuthToken()) {
    push('/dashboard');
  }

  const handleRegistration = (values) => {
    mutateAsync(
      { email: values?.email },
      {
        onSuccess: () => {
          push('/verify-email-success', { params: values?.email });
        },
      },
    );
  };

  return (
    <div>
      <HeaderComponent hasLoginButton />
      <Spin spinning={isLoading}>
        <div className="registration-page-wrapper">
          <GreenBox title="会員登録">
            <form onSubmit={handleSubmit(handleRegistration)}>
              <div className="email-input-wrapper">
                <Row justify="center">
                  <div className="w-343">
                    <TextInput
                      name="email"
                      noLabel
                      control={control}
                      required
                      placeholder="メールアドレス"
                    />
                  </div>
                </Row>
              </div>
              <Row justify="center">
                <Button
                  className="registration-btn second-color"
                  htmlType="submit"
                  type="primary"
                  disabled={!isValid}
                >
                  Experportに登録する
                </Button>
              </Row>

              <div className="registration-policy">
                利用を開始することで、
                <a href={termsOfUseLink} target="_blank" className="policy-link" rel="noreferrer">
                  利用規約
                </a>
                (
                <a href={dataPolicyLink} target="_blank" className="policy-link" rel="noreferrer">
                  データ取扱規則を含む
                </a>
                ) &nbsp; /&nbsp; &nbsp;
                <a
                  href={privacyPolicyLink}
                  target="_blank"
                  rel="noreferrer"
                  className="policy-link"
                >
                  プライバシーポリシー
                </a>
                に同意したとみなします
              </div>
            </form>
          </GreenBox>
        </div>
      </Spin>
    </div>
  );
};

export default PreCheckEmailSignUpPage;
