/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Typography, Space } from 'antd';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import RolesForm from 'components/skills/RolesForm';
import RolesList from 'components/skills/RolesList';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import { useFetchUser, useMutate } from 'hooks';
import useFetchList from 'hooks/useList';
import { ICertInitValue, IRolesInitValue } from 'models/skills/interface';
import skillsQuery from 'models/skills/query';
import Helper from 'utils/helpers';
import headImage from 'assets/images/self-introduction-icon.svg';

import CertificationsForm from 'components/skills/CertificateForm';
import CertificationsList from 'components/skills/CertificateList';

const { Title } = Typography;

const RolePage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:role'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:skills')} / ${t('menuBar:role')}`,
        },
      ],
    },
  };
  const dispatch = useAppDispatch();
  // state
  const [userRoles, setUserRoles] = useState({});
  const [userCertification, setUserCertification] = useState<any>({});

  const { data } = useFetchUser({ enabled: true });
  const {
    list: roleList,
    isLoading: fetchData,
    refetch,
  } = useFetchList<IRolesInitValue>({
    ...skillsQuery.roleList,
    customParams: {
      ...skillsQuery.roleList.customParams,
      userId: _get(data, '_id'),
    },
  });

  const { mutateAsync: deleteRoles, isLoading: isLoadingDelete } = useMutate<{
    roleExperience: string;
  }>(skillsQuery.deleteRoles);
  const {
    list: certificationList,
    isLoading: fetchingCert,
    refetch: refetchCertificationList,
  } = useFetchList<ICertInitValue>(skillsQuery.certificationList);
  const { mutateAsync: deleteCertification, isLoading: isLoadingDelCert } = useMutate<{
    certificationId: string;
  }>(skillsQuery.deleteCertification);

  // scroll to top form section when edit
  const handleScrollRoleForm = () => {
    const element = document.querySelector('.role-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollRoleCertificate = () => {
    const element = document.querySelector('.role-cert-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handLeDeleteRoles = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteRoles(
              { roleExperience: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  refetch();
                  resetInitialValue();
                },
              },
            );
          },
        },
      }),
    );
  };

  const handleEditRoles = (item) => {
    setUserRoles(item);
    handleScrollRoleForm();
  };
  // Edit and delete certification
  const handleEditCertification = (item) => {
    setUserCertification(item);
    handleScrollRoleCertificate();
  };
  const handleDeleteCertification = (id) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteCertification(
              { certificationId: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  refetchCertificationList();
                  resetInitialValueCert();
                },
              },
            );
          },
        },
      }),
    );
  };

  const resetInitialValue = () => {
    setUserRoles({});
  };
  const resetInitialValueCert = () => {
    setUserCertification({});
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper roles-page-wrapper">
        <div className="role-scroll"></div>
        <Typography style={{ padding: '0 15px 0 28px', fontWeight: 500 }}>
          担当可能な役割・工程とそのレベル・経験年数を登録しましょう。
        </Typography>
        <img className="head-image" src={headImage} alt="icon" />

        <div className="green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:rolesTitle')}</Title>
          <RolesForm initialValue={userRoles} resetInitialValue={resetInitialValue} />
          {roleList?.length > 0 && (
            <RolesList
              data={roleList}
              initLoading={fetchData || isLoadingDelete}
              handleEdit={handleEditRoles}
              handleDelete={handLeDeleteRoles}
            />
          )}
        </div>
        <div className="role-cert-scroll"></div>
        <div className="mt-80 green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:qualificationTitle')}</Title>
          <CertificationsForm
            initialValue={userCertification}
            resetInitialValue={resetInitialValueCert}
          />
          {certificationList?.length > 0 && (
            <CertificationsList
              data={certificationList}
              initLoading={fetchingCert || isLoadingDelCert}
              handleEdit={handleEditCertification}
              handleDelete={handleDeleteCertification}
            />
          )}
        </div>
      </Space>
    </PageContainer>
  );
};

export default RolePage;
