/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const INSTALLATION_ID = '@skeleton_installationId';

export const REG_EXP = {
  PASSWORD_POLICY:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]{8,}$/,
};
export enum Limit {
  MAX_LIMIT = 10000,
  AGENT_LIST = 10,
}
export enum UserStatus {
  ACTIVE = 'ACTIBE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}
export const ERRORS = {
  INVALID_SESSION_TOKEN: {
    code: 209,
    message: 'Invalid session token!',
  },
  EXISTED_EMAIL: {
    code: 202,
    message: 'Account already exists for this username.',
  },
};

export enum MenuHeader {
  LOGOUT = 'LOGOUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum MenuHeaderLabel {
  LOGOUT = 'Log out',
  CHANGE_PASSWORD = 'Change password',
}

export const GET_ACCESS_TOKEN_MINUTE_MS = 36000;
export const BADGE_COLOR = {
  ACCOUNT_STATUS: {
    active: {
      text: 'status:active',
      color: 'green',
    },
    pending: {
      text: 'status:pending',
      color: 'yellow',
    },
    disabled: {
      text: 'status:disabled',
      color: 'gray',
    },
  },
  PAYOUT_STATUS: {
    COMPLETED: {
      text: 'COMPLETED',
      color: 'cyan',
    },
    UNCOMPLETE: {
      text: 'UNCOMPLETE',
      color: 'volcano',
    },
  },
};
export enum MomentFormat {
  MONTH_YEAR_SHORT = 'MM/YY',
  YEAR_MONTH_DATE = 'YYYY/MM/DD',
  YEAR_MONTH = 'YYYY/MM',
  YEAR_MONTH_DASH = 'YYYY-MM',
  YEAR_MONTH_DATE_HOUR = 'YYYY/MM/DD HH:mm',
  YEAR_MONTH_DATE_HOUR_DASH = 'YYYY-MM-DD HH:mm',
  HOUR_YEAR_MONTH_DATE = 'HH:mm YYYY-MM-DD',
  HOUR_YEAR_MONTH_DATE_JP = 'HH:mm YYYY年MM月DD日',
  YYYY_MM = 'YYYY/MM',
  YYYY_MM_DD = 'YYYY/MM/DD',
  YYYY_MM_DD_DASH = 'YYYY-MM-DD',
  YYYY_MM_DD_HH_MM = 'YYYY/MM/DD HH:mm',
  YYYY_MM_DD_HH_MM_DASH = 'YYYY-MM-DD HH:mm',
  YYYY_MM_DD_HH_MM_SS_DASH = 'YYYY-MM-DD HH:mm:ss',
  HH_MM_SS_YYYY_MM_DD_DASH = 'HH:mm:ss YYYY-MM-DD',
}

export enum StatusColumnText {
  pending = 'Pending',
  active = 'Active',
  disabled = 'Disabled',
  cancelled = 'Cancelled',
  confirmed = 'Confirmed',
  complete = 'Complete',
}

export const BOOKING_STATUS = ['pending', 'confirmed', 'cancelled', 'complete', 'deleted'];

export const REGION_ENUM = {
  北海道: '北海道',
  東北: '東北',
  関東: '関東',
  中部: '中部',
  関西: '関西',
  中国: '中国',
  四国地方: '四国地方',
  九州: '九州',
};

export const ADDRESS_PRIVACY_ENUM = {
  Public: 'Public',
  Protect: 'Protect',
};

export const ACCOUNT_TYPE_ENUM = {
  Saving: 'Saving',
  Standard: 'Standard',
};

export const TRANSLATION = {
  male: 'profile:male',
  female: 'profile:female',
  unanswered: 'profile:unanswered',
  protect: 'type:protect',
  public: 'type:public',
  saving: 'type:saving',
  standard: 'type:standard',
};
export const notOutsourcingConTract = ['FREELANCE', 'EMPLOYMENT'];
export const isOutsourcingConTract = ['OUTSOURCING'];
export const isNotFreelanceContract = ['OUTSOURCING', 'EMPLOYMENT'];

export const CONTRACT_TYPE = [
  { _id: 'FREELANCE', name: '無期雇用契約' },
  { _id: 'EMPLOYMENT', name: '有期雇用契約' },
  { _id: 'OUTSOURCING', name: '業務委託契約' },
];
export const SOCIAL_INSURANCE_STATUS = [
  { _id: 'NOT_JOINED', name: '未加入' },
  { _id: 'JOINED', name: '加入済' },
  { _id: 'UNKNOWN', name: '不明' },
];
export const JOB_TITLE = [
  { label: 'システム営業', value: 'SYSTEM_SALES' },
  { label: 'SES・派遣営業', value: 'TEMPORARY_SALES' },
  { label: '人事', value: 'HR' },
  { label: '購買', value: 'BUYER' },
  { label: 'エンジニア', value: 'ENGINEER' },
];
export const Regex = {
  PASSWORD_POLICY:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]{8,}$/,
  // KATAKANA: /^[ｧ-ﾝﾞﾟァ-・ヽヾ゛゜ー()-.（-）]+$/,
  // eslint-disable-next-line no-useless-escape
  // Allow Alphabet(A-Za-z),
  //   Hiragana(u3040-u309f)
  //   Katakana(u30a0-u30ff),
  //   Half-width Katakana(uff65-uff9f)
  //   Kanji(u3400-u4dbf,u4e00-u9faf)
  //   々(u3005)
  // eslint-disable-next-line no-useless-escape
  ALPHABET_JP: /^[A-Za-z\u3040-\u309f\u30a0-\u30ff\uff65-\uff9f\u4e00-\u9faf\u3400-\u4dbf\u3005]+$/,
  KATAKANA: /^$|^[\x20\u3000\u30a0-\u30ff\uff65-\uff9f]+$/,
  URL: /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]).{15,}$/,
  PHONE: /^(?:\d{10,15}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/,
  WHITESPACE: /\s/,
  EMAIL:
    // eslint-disable-next-line no-useless-escape, no-control-regex
    /^[a-z0-9*+_-]+(?:\.[a-z0-9*+_-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(((?![-])[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
};
export const viewDatePickerOptions = [
  { label: 'day', value: 'DAY' },
  { label: 'week', value: 'WEEK' },
  { label: 'month', value: 'MONTH' },
  { label: 'payoutCycle', value: 'PAYOUT_CYCLE' },
  { label: 'custom', value: 'CUSTOM' },
];

export const VIEW_MODE = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  PAYOUT_CYCLE: 'PAYOUT_CYCLE',
  CUSTOM: 'CUSTOM',
};

export const LAGUANGE_LIST = ['en', 'ja'];
export const CUSTOM_LANG_DEFAULT = 'ja';
export const DEFAULT_TIMESTAMP = new Date().getTime();
export const RADIO_CHOOSE = [
  { label: 'NG', value: 'not_good' },
  { label: 'OK', value: 'ok' },
  { label: '優先', value: 'preference' },
];
export const AVATAR_COLOR = [
  '#E05F5F',
  '#F2C37B',
  '#7DAD96',
  '#7B98C3',
  '#AE93C3',
  '#B5958D',
  '#B1B2B2',
  '#3D405B',
];

export const OVERTIME_DAY_VALUES = {
  NO_OVERTIME: '残業なし',
  REGULAR_OVERTIME: '普通残業あり',
  LATE_NIGHT_OVERTIME: '深夜残業あり',
  HOLIDAY_SUPPORT: '休日対応あり',
};

export const CHARACTERISTICS = {
  COMPLIANCE: '開発標準の遵守が重要',
  UNDERSTANDING_SPECIFICATIONS: '仕様理解のハードルが高い',
  SPECIFICATION_CHANGES: '頻繁な仕様変更・追加がある',
  MAINTENANCE_EASE: '保守容易性を重視する開発方針',
  BUSINESS_ETIQUETTE: '常識的なビジネスマナーが重要',
  DEVELOPMENT_SCOPE: '開発範囲の網羅を優先し高稼働で対応',
  PARTNERSHIP_ROLE: 'パートナーに体制構築・拡大を率いる役割を期待',
  COMMUNICATION_FLEXIBILITY: '臨機応変に周りを巻き込んでコミュニケーションをとる必要がある',
  PROACTIVENESS: '主体性重視（待ち姿勢NG）',
  PROBLEM_DETECTION: '全体的に不確実性高めのため、問題発見・対応力が重要',
  OPERATION_INSTRUCTIONS: 'ユーザーに丁寧な操作説明が必要',
  TRANSFORMATION_CHALLENGES: '変革に挑戦中で新規提案を期待している',
  SOCIAL_SIGNIFICANCE: '社会的意義が高く困難な開発',
  SPECIALIZED_KNOWLEDGE: 'パートナーの専門知識・経験で開発のリードや若手の教育をして欲しい',
  ORGANIZATION_RULES: '組織に特有のルールが比較的多い',
};

export const WORK_STYLE = {
  REMOTE_ONLY: 'リモートのみ・推奨',
  MAINLY_REMOTE: 'リモート中心＋少し出勤',
  MAINLY_COMMUTING: '出勤中心＋少しリモート',
  ATTENDANCE_ONLY: '出勤のみ・推奨',
};

export type MetaProps = {
  notToastErrorCodes?: (number | string)[];
  noToastError?: boolean;
  showError?: boolean;
};
