import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
import TextArea from 'components/Form/TextArea';

// hooks
import useFetchList from 'hooks/useList';
import { IHelpTextData } from 'models/setting/interface';
import { useMutate, useFetchUser, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import Helper from 'utils/helpers';
import settingQuery from 'models/setting/query';

import './style.less';
import '../skills-style.less';

import { IRoles, IRolesPayload, IRolesUpdatePayload } from 'models/skills/interface';
import { ICategoryRoleItem, IProcessItem, ILevelItem } from 'models/resource/interface';
import RolesSchema from './validate';

type RolesFormValues = InferType<typeof RolesSchema>;

const RolesForm: React.FC<{
  initialValue: IRoles | Record<string, unknown>;
  resetInitialValue: () => void;
}> = ({ initialValue, resetInitialValue }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm<RolesFormValues>({
    resolver: yupResolver(RolesSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { data } = useFetchUser({ enabled: true });
  // get all role list - process list - level list
  const { list: listRole, isFetching: fetchingCategoryData } = useFetchList<ICategoryRoleItem>(
    resourceQuery.role,
  );
  const { list: listLevel, isFetching: fetchingLevelData } = useFetchList<ILevelItem>(
    resourceQuery.level,
  );

  // get Process list -> category role
  const categoryRoleValue = useWatch({ name: 'roleCategory', control: control });
  const { list: listProcess } = useFetchList<IProcessItem>({
    ...resourceQuery.process,
    customParams: {
      search: categoryRoleValue,
      perPage: 500,
    },
  });
  const roleProcessValue = useWatch({ name: 'roleProcess', control: control });

  const { refetch: refetchList } = useFetchList<IRoles>({
    ...skillsQuery.roleList,
    customParams: {
      ...skillsQuery.roleList.customParams,
      userId: _get(data, '_id'),
    },
  });
  const { mutateAsync: createRoles, isLoading: loadingCreate } = useMutate<IRolesPayload>(
    skillsQuery.createRoles,
  );
  const { mutateAsync: updateRoles, isLoading: loadingUpdate } = useMutate<IRolesUpdatePayload>(
    skillsQuery.updateRoles,
  );

  useEffect(() => {
    setInitialForm({
      ...initialValue,
      roleCategory: _get(initialValue, 'roleCategory._id'),
      roleProcess: _get(initialValue, 'roleProcess._id'),
      level: _get(initialValue, 'level._id'),
    });
    setValue('roleCategory', _get(initialValue, 'roleCategory._id', ''));
    setValue('roleProcess', _get(initialValue, 'roleProcess._id', ''));
    setValue('level', _get(initialValue, 'level._id', ''));
    clearErrors();
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  const clearUpdateForm = () => {
    setInitialForm({});
    reset({});
    resetInitialValue();
  };

  const handleRolesSubmit = (values: RolesFormValues) => {
    if (_isEmpty(_get(initialForm, '_id'))) {
      createRoles(
        {
          ...values,
          experienceYears: Number(values.experienceYears),
          user: data?._id || '',
          roleProcess: values.roleProcess,
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('skills:createSuccess'), 'success');
            reset();
            refetchList();
          },
        },
      );
    } else {
      updateRoles(
        {
          roleExperience: _get(initialForm, '_id', ''),
          ...values,
          experienceYears: Number(values.experienceYears),
          user: data?._id || '',
          roleProcess: values.roleProcess,
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refetchList();
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container roles-form-container"
      onSubmit={handleSubmit(handleRolesSubmit)}
    >
      <Spin spinning={fetchingCategoryData || fetchingLevelData || listRole.length <= 0}>
        <div className="roles-form-block">
          <div className="row-block">
            <SelectField
              name="roleCategory"
              label={t('skills:rolesField')}
              control={control}
              className="horizontal"
              required
              placeholder={t('placeholder:fieldRole')}
              data={listRole}
              colLabel={14}
              colWrapper={12}
              formLayout="vertical"
              onSelect={(value) => {
                if (!_isEqual(value, categoryRoleValue)) {
                  setValue('roleProcess', '');
                  setValue('level', '');
                }
              }}
              onClear={() => {
                setValue('roleProcess', '');
                setValue('level', '');
                setValue('roleCategory', '', { shouldValidate: true });
                clearErrors('roleProcess');
                clearErrors('level');
              }}
              onChange={(val) => setValue('roleCategory', val, { shouldValidate: true })}
              helperText={_get(dataHelpText, 'value.formHelpTextRolePage.role')}
            />
          </div>
          <div className="row-block">
            <SelectField
              name="roleProcess"
              control={control}
              className="w-130"
              required
              placeholder={t('placeholder:fieldProcess')}
              data={listProcess}
              onSelect={(value) => {
                if (!_isEqual(value, roleProcessValue)) {
                  setValue('level', '');
                }
              }}
              onClear={() => {
                setValue('roleProcess', '', { shouldValidate: true });
                setValue('level', '');
              }}
              disabled={!categoryRoleValue}
              onChange={(val) => setValue('roleProcess', val)}
              label={t('skills:processField')}
              colLabel={14}
              colWrapper={12}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextRolePage.process')}
            />
          </div>
          <div className="row-block">
            <SelectField
              label={t('skills:levelField')}
              name="level"
              control={control}
              className="w-130"
              required
              placeholder={t('placeholder:fieldLevel')}
              data={listLevel}
              onClear={() => setValue('level', '', { shouldValidate: true })}
              disabled={!categoryRoleValue || !roleProcessValue}
              colLabel={14}
              colWrapper={12}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextRolePage.level')}
            />
          </div>
          <div className="row-block number-year">
            <TextInput
              name="experienceYears"
              control={control}
              label={t('skills:experienceYearsField')}
              required
              placeholder={t('placeholder:fieldExperienceYears')}
              suffix="年"
              suffixOutside={false}
              colLabel={14}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>
          <div className="row-block full-row">
            <TextArea
              name="detail"
              control={control}
              label={t('skills:detailField')}
              placeholder={t('placeholder:fieldDetail')}
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={14}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextRolePage.detail')}
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(_get(initialForm, '_id')) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => clearUpdateForm()}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default RolesForm;
