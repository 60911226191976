const authQuery = {
  login: {
    apiUrl: '/v1/user/login',
    meta: {
      showError: true,
    },
  },
  preCheckEmail: {
    apiUrl: '/v1/public/user/pre-sign-up-Check',
    meta: {
      showError: true,
    },
  },
  resetPasswordRequest: {
    apiUrl: '/v1/public/user/send-mail-forgot-password',
    meta: {
      showError: true,
    },
  },
  checkExpiredLink: {
    apiUrl: '/v1/public/user/check-expried-link-forgot-password',
    meta: {
      showError: true,
    },
  },
  updateNewPassword: {
    apiUrl: '/v1/public/user/forgot-password',
    meta: {
      showError: true,
    },
  },
  checkExpiredTokenRegister: {
    apiUrl: '/v1/public/user/check-expired-token-sign-up',
    meta: {
      showError: true,
    },
  },
  register: {
    apiUrl: '/v1/public/user/sign-up',
    meta: {
      showError: true,
    },
  },
  getProfile: {
    apiUrl: '/v1/user/profile',
    queryKey: ['currentUser', 'profile'],
    staleTime: Infinity,
  },
  logout: {
    apiUrl: '/v1/user/logout',
  },
  requestChangeEmail: {
    apiUrl: '/v1/user/request-change-email',
    meta: {
      showError: true,
    },
  },
  confirmChangeEmail: {
    apiUrl: '/v1/public/user/confirm-change-email',
    meta: {
      showError: true,
    },
  },
  updateProfile: {
    apiUrl: '/v1/user/update-account',
    method: 'patch',
    meta: {
      showError: true,
    },
  },
  changePasswords: {
    apiUrl: '/v1/user/change-password',
    method: 'patch',
    meta: {
      showError: true,
    },
  },
  checkLinkingToken: {
    apiUrl: '/v1/public/user/check-engineer-linking-token',
    method: 'post',
  },
  confirmLinkingEngineer: {
    apiUrl: '/v1/user/confirm-engineer-linking',
    method: 'post',
    meta: {
      showError: true,
    },
  },
  updateAvatarColor: {
    apiUrl: '/v1/user/update-avatar-color',
    method: 'patch',
    meta: {
      showError: true,
    },
  },
  updateLinkCompany: {
    apiUrl: '/v1/user/unlink-aperport-company',
    method: 'post',
    meta: {
      showError: true,
    },
  },
  confirmLinkingSale: {
    apiUrl: '/v1/user/confirm-sale-linking',
    method: 'post',
    meta: {
      showError: true,
    },
  },
};

export default authQuery;
