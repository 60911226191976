import { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
// component
import MaskedInput from 'components/Form/MaskedInput';
import { TextInput } from 'components/Form';
import SelectField from 'components/Form/Select';
import CheckBoxGroup from 'components/Form/CheckboxGroup';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate } from 'hooks';
import resourceQuery from 'models/resource/query';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';
import ecLogo from 'assets/images/ec-logo.svg';
import {
  CONTRACT_TYPE,
  isNotFreelanceContract,
  isOutsourcingConTract,
  JOB_TITLE,
  notOutsourcingConTract,
  SOCIAL_INSURANCE_STATUS,
} from 'utils/constants';
import './style.less';

import {
  ICityItem,
  ICountryItem,
  ILineItem,
  IPrefectureItem,
  IStationItem,
} from 'models/resource/interface';
import {
  ICheckExpiredTokenRegisterPayload,
  ILoginPayload,
  IRegisterPayload,
} from 'models/auth/interface';
import RegisterSchema from './validate';

type RegisterFormValues = InferType<typeof RegisterSchema>;

const RegisterForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    clearErrors,
  } = useForm<RegisterFormValues>({
    resolver: yupResolver(RegisterSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const location = useLocation();
  const tokenInviteAP = _get(location, 'state.token');
  const isNewUserLinkAP = _get(location, 'state.isNewUser');
  const token = (searchParams.get('token') || tokenInviteAP) as string;
  // state
  const [userEmail, setUserEmail] = useState('');
  const contractType = useWatch({ name: 'contractType', control: control });

  // APIs
  const { mutateAsync: login } = useMutate<ILoginPayload, { accessToken: string }>(authQuery.login);
  const { mutateAsync: register, isLoading } = useMutate<IRegisterPayload>(authQuery.register);
  const { mutateAsync: checkExpiredLinkRegister } = useMutate<ICheckExpiredTokenRegisterPayload>(
    authQuery.checkExpiredTokenRegister,
  );
  // get all country list and prefecture list
  const { list: listCountry } = useFetchList<ICountryItem>(resourceQuery.country);
  const { list: listPrefecture } = useFetchList<IPrefectureItem>(resourceQuery.prefecture);

  // get Cities list  -> prefecture
  const prefectureValue = useWatch({ name: 'prefecture', control: control });
  const prefecture = listPrefecture.find((p) => p._id === prefectureValue);
  const isoKey = prefecture?.iso;
  const { list: listCity, isFetching: loadingCity } = useFetchList<ICityItem>({
    ...resourceQuery.city,
    enabled: !!prefectureValue,
    apiUrl: 'v1/public/city/list',
    queryKey: ['public', 'city', prefectureValue],
    customParams: {
      search: prefectureValue,
      perPage: 500,
    },
  });
  // get Lines list -> prefecture
  const { list: listLine } = useFetchList<ILineItem>({
    ...resourceQuery.line,
    enabled: !!prefectureValue,
    apiUrl: `v1/public/station/lines/${isoKey}`,
    queryKey: ['public', 'lines', prefectureValue],
    customParams: {
      search: isoKey,
      perPage: 500,
    },
  });

  // get Station nearest list -> lines
  const lineValue = useWatch({ name: 'lineStation', control: control });
  const { list: listStation, isFetching: loadingStation } = useFetchList<IStationItem>({
    ...resourceQuery.station,
    enabled: !!lineValue,
    apiUrl: `v1/public/station/${lineValue}`,
    queryKey: ['public', 'station', lineValue],
  });

  const resetValuePrefectureChanged = (value) => {
    setValue('prefecture', value, { shouldValidate: true });
    setValue('city', '');
    setValue('lineStation', '');
    setValue('stationNearest', '');
    clearErrors('city');
    clearErrors('lineStation');
    clearErrors('stationNearest');
  };

  // Check valid token register
  const checkExpiredToken = () => {
    checkExpiredLinkRegister(
      { token: token },
      {
        onSuccess: (data) => {
          setUserEmail(data?.email);
        },
        onError: () => {
          setTimeout(() => history.push('/'), 1500);
        },
      },
    );
  };
  useEffect(() => checkExpiredToken(), []);
  useEffect(() => {
    if (contractType !== 'FREELANCE') {
      setValue('employmentPeriodEndTime', '');
      clearErrors('employmentPeriodEndTime');
      clearErrors('buildingName');
      clearErrors('companyNameOriginal');
      clearErrors('affiliatedCompanyName');
    }
  }, [contractType]);

  const handleRegister = (values: RegisterFormValues) => {
    checkExpiredToken();
    const lineStation = listLine.find((l) => l._id === values?.lineStation);
    const stationNearest = listStation.find((l) => l._id === values?.stationNearest);
    register(
      {
        ...values,
        token: isNewUserLinkAP ? tokenInviteAP : token,
        email: userEmail,
        jobTitle: values.jobTitle,
        birthday: values?.birthday ? moment(values?.birthday).toISOString() : undefined,
        employmentPeriodStartTime: values?.employmentPeriodStartTime
          ? moment(values.employmentPeriodStartTime).toISOString()
          : undefined,
        employmentPeriodEndTime: values?.employmentPeriodEndTime
          ? moment(values?.employmentPeriodEndTime).toISOString()
          : undefined,
        trusteeBusinessPeriodStartTime: values?.trusteeBusinessPeriodStartTime
          ? moment(values.trusteeBusinessPeriodStartTime).toISOString()
          : undefined,
        trusteeBusinessPeriodEndTime: values?.trusteeBusinessPeriodEndTime
          ? moment(values?.trusteeBusinessPeriodEndTime).toISOString()
          : undefined,
        lineStation: _pick(lineStation, ['_id', 'name', 'nameKata', 'nameHira']) as ILineItem,
        stationNearest: _pick(stationNearest, [
          '_id',
          'name',
          'nameKata',
          'nameRoman',
        ]) as IStationItem,
      },
      {
        onSuccess: (data) => {
          login(
            { email: userEmail, password: values?.password },
            {
              onSuccess: (loginData) => {
                if (loginData.accessToken) {
                  Helper.storeAuthToken(loginData.accessToken);
                }
                if (!isNewUserLinkAP) {
                  history.push('/dashboard');
                } else {
                  history.push('engineer-linking-confirmation', { tokenInviteAP: data?.token });
                }
              },
            },
          );
        },
      },
    );
  };
  return (
    <form className="register-form-container" onSubmit={handleSubmit(handleRegister)}>
      <div className="row-block">
        <Link to={'/'}>
          <img className="logo" src={ecLogo} alt="logo" />
        </Link>
      </div>

      <div className="register-form-block">
        {/* Login infor */}
        <div className="login-info-section">
          <div className="row-block">
            <div>
              <div className="login-info">ログイン情報</div>
            </div>
            <div className="rectangular-block"></div>
          </div>

          <div className="email-address-title">メールアドレス</div>
          <div className="email-address-content">{userEmail}</div>
          <div className="mt-40">
            <TextInput
              label="パスワード（15桁以上の英大文字小文字数字記号）"
              name="password"
              control={control}
              required
              inputType="password"
              style={{ width: 425 }}
            />
          </div>
        </div>
        {/* User information */}
        <div className="row-block mt-59">
          <div>
            <div className="user-info">ユーザー情報</div>
          </div>
          <div className="rectangular-block"></div>
        </div>
        <div className="row-block pt-48">
          <div className="w-340">
            <TextInput
              name="fullName"
              label="氏名"
              control={control}
              required
              placeholder="例）鈴木　太郎"
            />
          </div>
          <div className="w-340">
            <TextInput
              name="phonetic"
              label="氏名（ヨミガナ）"
              control={control}
              placeholder="例）スズキ　タロウ"
            />
          </div>
        </div>
        <div className="pt-16">
          <TextInput
            name="username"
            label="ユーザー名"
            helperText="※Experport上で表示される名前です。未入力の場合は、氏名が表示されます。"
            control={control}
            className="w-340"
            placeholder="例）Taro"
          />
        </div>
        <div className="pt-16">
          <TextInput
            name="initial"
            label="イニシャル"
            helperText="※Experportと連携されるサービス(Aperport)や、公開用リンク先のページで表示されます。"
            control={control}
            className="w-340"
            placeholder="例）Y.S"
            required
          />
        </div>
        <div className="pt-16">
          <SelectField
            name="contractType"
            label="契約形態"
            control={control}
            className="w-340"
            required
            placeholder="選択してください"
            data={CONTRACT_TYPE}
            onClear={() => setValue('contractType', '', { shouldValidate: true })}
          />
        </div>
        {!isOutsourcingConTract.includes(contractType) && (
          <div className="pt-16">
            <TextInput
              name="affiliatedCompanyName"
              label="所属会社名"
              control={control}
              className="w-340"
              required={notOutsourcingConTract.includes(contractType)}
              placeholder="例）スフィアネット株式会社"
            />
          </div>
        )}
        {isOutsourcingConTract.includes(contractType) && (
          <div className="pt-16">
            <TextInput
              name="buildingName"
              label="屋号・法人名"
              control={control}
              className="w-340"
              placeholder="例）スフィアネット株式会社"
            />
          </div>
        )}
        {isOutsourcingConTract.includes(contractType) && (
          <div className="pt-16">
            <TextInput
              name="companyNameOriginal"
              label="所属先（業務委託契約先）会社名"
              control={control}
              required={isOutsourcingConTract.includes(contractType)}
              className="w-745"
              placeholder="例）スフィアネット株式会社"
            />
          </div>
        )}

        <div className="pt-16">
          <TextInput
            name="companyMail"
            label="所属会社発行のメールアドレス"
            control={control}
            required
            className="w-340"
            placeholder="例）mail@spherenet.o.jp"
          />
        </div>
        {notOutsourcingConTract.includes(contractType) && (
          <div className="row-block-period-date pt-16">
            <div>
              <MaskedInput
                name="employmentPeriodStartTime"
                mask="9999/99/99"
                label="雇用期間"
                required
                placeholder="YYYY/MM/DD"
                control={control}
                className="mask-input"
                style={{ width: 340 }}
              />
            </div>
            {isNotFreelanceContract.includes(contractType) && (
              <>
                <div className="icon-between-fields"> ～ </div>
                <div>
                  <MaskedInput
                    name="employmentPeriodEndTime"
                    mask="9999/99/99"
                    noLabel={true}
                    required={isNotFreelanceContract.includes(contractType)}
                    placeholder="YYYY/MM/DD"
                    control={control}
                    className="mask-input"
                    style={{ width: 340 }}
                  />
                </div>
              </>
            )}
          </div>
        )}

        {isOutsourcingConTract.includes(contractType) && (
          <div className="row-block-period-date pt-16">
            <div>
              <MaskedInput
                name="trusteeBusinessPeriodStartTime"
                mask="9999/99/99"
                label="業務受託期間"
                required
                placeholder="YYYY/MM/DD"
                control={control}
                style={{ width: 340 }}
                className="mask-input"
              />
            </div>
            <div className="icon-between-fields"> ～ </div>
            <div>
              <MaskedInput
                name="trusteeBusinessPeriodEndTime"
                mask="9999/99/99"
                noLabel={true}
                required={isNotFreelanceContract.includes(contractType)}
                placeholder="YYYY/MM/DD"
                control={control}
                style={{ width: 340 }}
                className="mask-input"
              />
            </div>
          </div>
        )}

        <div className="pt-16">
          <SelectField
            name="socialInsuranceStatus"
            label="社会保険加入状況"
            control={control}
            className="w-340"
            required
            placeholder="選択してください"
            data={SOCIAL_INSURANCE_STATUS}
            onClear={() => setValue('socialInsuranceStatus', '', { shouldValidate: true })}
          />
        </div>

        <div className="pt-16">
          <CheckBoxGroup
            name="jobTitle"
            label="職種"
            control={control}
            required
            placeholder="選択してください"
            options={JOB_TITLE}
          />
        </div>

        <div className="row-block pt-16">
          <div className="w-340">
            <SelectField
              name="countryCitizenship"
              label="国籍"
              control={control}
              placeholder="選択してください"
              data={listCountry}
            />
          </div>
          <div className="w-340">
            <SelectField
              name="countryOrigin"
              label="出身国・地域"
              control={control}
              placeholder="選択してください"
              data={listCountry}
            />
          </div>
        </div>

        <div className="row-block pt-16">
          <div className="w-340">
            <SelectField
              name="prefecture"
              label="住所"
              control={control}
              required
              placeholder="都道府県"
              onSelect={(value) => {
                if (!_isEqual(value, prefectureValue)) {
                  resetValuePrefectureChanged(value);
                }
              }}
              onClear={() => resetValuePrefectureChanged('')}
              data={listPrefecture}
            />
          </div>
          <div className="w-340">
            <SelectField
              name="city"
              noLabel={true}
              control={control}
              placeholder="市区町村"
              disabled={!prefectureValue}
              data={listCity}
              loading={loadingCity}
              onClear={() => setValue('city', '', { shouldValidate: true })}
            />
          </div>
        </div>
        <div className="row-block pt-16">
          <div className="w-340">
            <SelectField
              name="lineStation"
              label="最寄り駅"
              control={control}
              required
              placeholder="路線"
              disabled={!prefectureValue}
              onSelect={(value) => {
                if (!_isEqual(value, lineValue)) {
                  setValue('stationNearest', '');
                }
              }}
              onClear={() => {
                setValue('lineStation', '', { shouldValidate: true });
                setValue('stationNearest', '');
                clearErrors('stationNearest');
              }}
              data={listLine}
            />
          </div>
          <div className="w-340">
            <SelectField
              name="stationNearest"
              noLabel={true}
              control={control}
              required
              placeholder="駅名"
              disabled={!prefectureValue || !lineValue}
              loading={loadingStation}
              data={listStation}
              onClear={() => setValue('stationNearest', '', { shouldValidate: true })}
            />
          </div>
        </div>

        <div className="pt-16">
          <MaskedInput
            mask="9999/99/99"
            label="生年月日"
            name="birthday"
            placeholder="YYYY/MM/DD"
            control={control}
            style={{ width: 340 }}
          />
        </div>

        <div className="submit-btn-wrapper">
          <Button
            htmlType="submit"
            className="register-btn second-color"
            type="primary"
            loading={isLoading}
            disabled={!isValid}
          >
            登録する
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RegisterForm;
