const saleCompanyQuery = {
  unlinkSaleCompany: {
    apiUrl: '/v1/user/unlink-aperport-sale-linking',
    method: 'post',
    meta: {
      showError: true,
    },
  },
  list: {
    apiUrl: '/v1/user/get-sale-linking',
    method: 'post',
  },
};

export default saleCompanyQuery;
