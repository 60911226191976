/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const skillsQuery = {
  roleList: {
    apiUrl: '/v1/experience/role/list',
    queryKey: ['public', 'roleList'],
    customParams: {
      perPage: 100,
      sort: 'createdAt@asc',
      limit: 20,
    },
    staleTime: Infinity,
  },
  createRoles: {
    apiUrl: '/v1/experience/role/create',
    meta: {
      showError: true,
    },
  },
  updateRoles: {
    apiUrl: ({ roleExperience }: { roleExperience: string }) =>
      `/v1/experience/role/update/${roleExperience}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteRoles: {
    apiUrl: ({ roleExperience }: { roleExperience: string }) =>
      `/v1/experience/role/delete/${roleExperience}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
  certificationList: {
    apiUrl: '/v1/experience/certifications/list',
    queryKey: ['role', 'certificationList'],
    customParams: {
      perPage: 20,
      sort: 'acquisitionAt@asc,createdAt@asc',
      type: 'ROLE',
    },
    staleTime: Infinity,
  },
  knowledgeCertificationList: {
    apiUrl: '/v1/experience/certifications/list',
    queryKey: ['role', 'certificationList'],
    customParams: {
      perPage: 20,
      sort: 'acquisitionAt@asc,createdAt@asc',
      type: 'DOMAIN_KNOWLEDGE',
    },
    staleTime: Infinity,
  },
  languageCertificationList: {
    apiUrl: '/v1/experience/certifications/list',
    queryKey: ['language', 'certificationList'],
    customParams: {
      perPage: 20,
      sort: 'acquisitionAt@asc,createdAt@asc',
      type: 'LANGUAGE',
    },
    staleTime: Infinity,
  },
  technicalCertificationList: {
    apiUrl: '/v1/experience/certifications/list',
    queryKey: ['technical', 'certificationList'],
    customParams: {
      perPage: 20,
      sort: 'acquisitionAt@asc,createdAt@asc',
      type: 'TECHNICAL_SKILL',
    },
    staleTime: Infinity,
  },
  createCertification: {
    apiUrl: '/v1/experience/certifications/create',
    meta: {
      showError: true,
    },
  },
  updateCertification: {
    apiUrl: ({ certificationId }: { certificationId: string }) =>
      `/v1/experience/certifications/update/${certificationId}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteCertification: {
    apiUrl: ({ certificationId }: { certificationId: string }) =>
      `/v1/experience/certifications/delete/${certificationId}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
  knowledgeList: {
    apiUrl: '/v1/experience/domain-knowledge/list',
    queryKey: ['public', 'knowledgeList'],
    customParams: {
      perPage: 100,
      sort: 'createdAt@asc',
    },
    staleTime: Infinity,
  },
  createKnowledge: {
    apiUrl: '/v1/experience/domain-knowledge/create',
    meta: {
      showError: true,
    },
  },
  updateKnowledge: {
    apiUrl: ({ domainKnowledgeExperience }: { domainKnowledgeExperience: string }) =>
      `/v1/experience/domain-knowledge/update/${domainKnowledgeExperience}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteKnowledge: {
    apiUrl: ({ domainKnowledgeExperience }: { domainKnowledgeExperience: string }) =>
      `/v1/experience/domain-knowledge/delete/${domainKnowledgeExperience}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
  languageList: {
    apiUrl: '/v1/experience/language/list',
    queryKey: ['public', 'languageList'],
    customParams: {
      perPage: 100,
      sort: 'createdAt@asc',
    },
    staleTime: Infinity,
  },
  createLanguage: {
    apiUrl: '/v1/experience/language/create',
    meta: {
      showError: true,
    },
  },
  updateLanguage: {
    apiUrl: ({ languageExperience }: { languageExperience: string }) =>
      `/v1/experience/language/update/${languageExperience}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteLanguage: {
    apiUrl: ({ languageExperience }: { languageExperience: string }) =>
      `/v1/experience/language/delete/${languageExperience}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
  technicalList: {
    apiUrl: '/v1/experience/technical/list',
    queryKey: ['public', 'technicalList'],
    customParams: {
      perPage: 100,
      sort: 'createdAt@asc',
      group: true,
    },
    staleTime: Infinity,
  },
  createTechnical: {
    apiUrl: '/v1/experience/technical/create',
    meta: {
      showError: true,
    },
  },
  updateTechnical: {
    apiUrl: ({ categoryTechnical }: { categoryTechnical: string }) =>
      `/v1/experience/technical/update/${categoryTechnical}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteTechnical: {
    apiUrl: ({ categoryTechnical }: { categoryTechnical: string }) =>
      `/v1/experience/technical/delete/${categoryTechnical}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
  workExperienceList: {
    apiUrl: '/v1/experience/work/list',
    queryKey: ['public', 'workExperience'],
    customParams: {
      perPage: 100,
      sort: 'periodStart@desc',
    },
    staleTime: Infinity,
  },
  createWorkExperience: {
    apiUrl: '/v1/experience/work/create',
    meta: {
      showError: true,
    },
  },
  updateWorkExperience: {
    apiUrl: ({ workExperience }: { workExperience: string }) =>
      `/v1/experience/work/update/${workExperience}`,
    method: 'put',
    meta: {
      showError: true,
    },
  },
  deleteWorkExperience: {
    apiUrl: ({ workExperience }: { workExperience: string }) =>
      `/v1/experience/work/delete/${workExperience}`,
    method: 'delete',
    mode: 'cors',
    meta: {
      showError: true,
    },
  },
};

export default skillsQuery;
