/* eslint-disable import/no-cycle */
import { Mutation, MutationCache, Query, QueryCache, QueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import Helper from 'utils/helpers';
import { MetaProps } from './constants';

const handleError = async (
  error: unknown,
  query?: Query,
  context?: unknown,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
) => {
  const errorMessage: string = get(error, 'message', '') as string;
  const errorCode = get(error, 'code');

  if (errorMessage === 'Network Error') {
    Helper.toasts('', 'ネットワークに接続されていません。', 'error');
  } else {
    if (mutation && mutation.meta) {
      const { showError }: MetaProps = mutation.meta;
      if (showError && errorMessage) {
        Helper.toasts('', errorMessage, 'error');
        return;
      }
    }
    if (query && query.meta) {
      const { showError }: MetaProps = query.meta;
      if (showError && errorMessage) {
        Helper.toasts('', errorMessage, 'error');
        return;
      }
    }
  }

  // code === 5100 is UNAUTHORIZED ACCESS
  if (errorCode === 5100 || errorCode === 'BE-LG001') {
    const queryClient = new QueryClient();
    setTimeout(() => {
      Helper.removeAuthToken();
      Helper.removeChangePassowrd();
    });
    queryClient.invalidateQueries(['currentUser'], {
      refetchType: 'none',
    });
    queryClient.removeQueries(['currentUser']);
    if (!get(query, 'email')) {
      window.location.reload();
    }
  }
  // Helper.toast(errorMessage, { type: 'error', toastId: errorMessage });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: true,
      refetchOnWindowFocus: false,
      retry: false,
      suspense: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
      onError: (error, query, context) => {
        handleError(error, query as Query, context);
      },
    },
  },
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) =>
      handleError(error, variables as Query, context, mutation),
  }),
  queryCache: new QueryCache({
    onError: (error, query) => handleError(error, query),
  }),
});

export default queryClient;
